import React, { useState, ChangeEvent, MouseEvent } from 'react';
import Input from '@components/input';
import Text from '@components/text';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import {
  Container,
  TextContainer,
  Title,
  StyledRichTextContainer,
  MessageContainer,
  StyledForm,
  FormRow,
  InputContainer,
  StyledText,
  Textarea,
  StyledButton,
} from './contact-form.styled';

// Add a correct portalId and formId for HubSpot
const portalId = '21106878';
const formId = '7fadfb34-bd4a-4755-b233-db2b70794b08';
const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

interface ContactFormProps {
  title: string;
  description: RichTextBlock[];
}

const TextBlock = ({ title, description }: ContactFormProps) => (
  <TextContainer>
    <Title tag="h3" textSize="heading3" type="title" stringText={title} />
    <StyledRichTextContainer>
      <RichText render={description} />
    </StyledRichTextContainer>
  </TextContainer>
);

const ContactForm = ({ title, description }: ContactFormProps): JSX.Element => {
  const [status, setStatus] = useState('ready');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [comments, setComments] = useState('');

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComments(e.target.value);
  };

  const handleSubmit = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();

    const data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'firstname',
          value: firstName,
        },
        {
          name: 'lastname',
          value: lastName,
        },
        {
          name: 'company',
          value: companyName,
        },
        {
          name: 'jobtitle',
          value: jobTitle,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'phone',
          value: phoneNumber,
        },
        {
          name: 'message',
          value: comments,
        },
      ],
      context: {
        pageUri: 'subscribe-success',
        pageName: 'Subscribe successful',
      },
    };
    const final_data = JSON.stringify(data);

    const xhr = new XMLHttpRequest();

    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          setStatus('success');
        } else if (xhr.status === 400) {
          setStatus('error');
        } else if (xhr.status === 403) {
          setStatus('error');
        } else {
          setStatus('error');
        }
      }
    };
    xhr.send(final_data);
  };

  if (status === 'success') {
    return (
      <Container>
        <TextBlock title={title} description={description} />
        <StyledForm>
          <MessageContainer>
            <Title
              tag="h3"
              textSize="heading3"
              type="title"
              stringText="Thanks for filling out the form! We'll be in touch shortly"
            />
          </MessageContainer>
        </StyledForm>
      </Container>
    );
  }

  return (
    <Container>
      <TextBlock title={title} description={description} />
      <StyledForm>
        {status === 'error' && (
          <MessageContainer>
            <Text type="eyebrow" stringText="Something went wrong. Please try again" />
          </MessageContainer>
        )}
        <FormRow>
          <InputContainer>
            <StyledText type="title" textSize="body" stringText="First name" />
            <Input
              type="text"
              value={firstName}
              onChange={setFirstName}
              placeholder="Your first name"
            />
          </InputContainer>
          <InputContainer>
            <StyledText type="title" textSize="eyebrow" stringText="Last name" />
            <Input
              type="text"
              value={lastName}
              onChange={setLastName}
              placeholder="Your last name"
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <StyledText type="title" textSize="eyebrow" stringText="Company" />
            <Input
              type="text"
              value={companyName}
              onChange={setCompanyName}
              placeholder="Your company’s name"
            />
          </InputContainer>
          <InputContainer>
            <StyledText type="title" textSize="eyebrow" stringText="Job Title" />
            <Input
              type="text"
              value={jobTitle}
              onChange={setJobTitle}
              placeholder="Your job title"
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <StyledText type="title" textSize="eyebrow" stringText="Email" />
            <Input type="email" value={email} onChange={setEmail} placeholder="Your email" />
          </InputContainer>
          <InputContainer>
            <StyledText type="title" textSize="eyebrow" stringText="Phone No.  (Optional)" />
            <Input
              type="tel"
              value={phoneNumber}
              onChange={setPhoneNumber}
              placeholder="Your phone number"
            />
          </InputContainer>
        </FormRow>
        <InputContainer>
          <StyledText type="title" textSize="eyebrow" stringText="Comments (Optional)" />
          <Textarea value={comments} onChange={handleTextAreaChange} rows={6} />
        </InputContainer>
        <StyledButton onClick={handleSubmit}>Request a Demo</StyledButton>
      </StyledForm>
    </Container>
  );
};

export default ContactForm;
