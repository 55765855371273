import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import { RichTextBlock } from 'prismic-reactjs';
import { StyledSection } from '@components/contact-form/contact-form.styled';
import Wrapper from '@components/wrapper.styled';
import ContactForm from '@components/contact-form';

interface ContactInterface {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    prismicContact: {
      data: {
        page_title: {
          text: string;
        };
        title: {
          text: string;
        };
        description: {
          richText: RichTextBlock[];
        };
      };
    };
  };
}

const Contact = ({ data }: ContactInterface): JSX.Element | null => {
  if (!data) return null;

  const {
    prismicGeneralConfig: {
      data: { favicon },
    },
    prismicContact: {
      data: { page_title, title, description },
    },
  } = data;

  return (
    <Layout isAlternativeFooterProvided>
      <SEO title={page_title.text} faviconHref={favicon?.url || ''} />
      <StyledSection backgroundColor="white">
        <Wrapper>
          <ContactForm title={title?.text} description={description?.richText} />
        </Wrapper>
      </StyledSection>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query ContactPageQuery {
    prismicGeneralConfig {
      ...GeneralConfigFragment
    }
    prismicContact {
      data {
        page_title {
          text
        }
        title {
          text
        }
        description {
          richText
        }
      }
    }
  }
`;
