import styled, { css } from 'styled-components';
import Text from '@components/text';
import Section from '@components/section';
import { Button } from '@styles/button.styled';
import BackgroundImage from '@images/contact-bg.svg';
import { generateScales } from '@helpers/generate-media-queries';
import { RichTextContainer } from '@components/paragraphs/paragraphs.shared.styled';

export const StyledSection = styled(Section)`
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: 175% auto;
  background-position: right top;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    column-gap: 3rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 2rem;
    }
  `}
`;

export const TextContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 29.5rem;
    font-family: ${theme.fontFamily.display};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 100%;
    }
  `}
`;

export const Title = styled(Text)`
  font-weight: normal;
`;

export const StyledRichTextContainer = styled(RichTextContainer)`
  ${({ theme }) => css`
    margin: 1rem 0 0 0;
    font-family: ${theme.fontFamily.display};
    font-size: ${theme.fontSize.subheading.desktop};
    font-weight: 400;
    color: ${theme.colors.bottleGreen};
    line-height: ${theme.lineHeights.lg};
    a,
    a:visited {
      color: ${theme.colors.bottleGreen};
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      font-size: 1rem;
    }
  `}
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 44rem;
`;

export const FormRow = styled.div`
  ${({ theme }) => `
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  margin-bottom: 1.5rem;
    
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: 1fr;
      row-gap: 1.5rem;
    }
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled(Text)`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return `
      flex-grow: 1;
      font-weight: 500;
      margin-bottom: 0;
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('height', mobile.height, desktop.height)}
      ${generateScales('line-height', mobile.height, desktop.height)}
  `;
  }}
`;

export const Textarea = styled.textarea`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return `
      resize: none;
      margin-bottom: 1.5rem;
      border: 2px solid ${theme.colors.jungleGreen};
      background-color: ${theme.colors.white};
      color: ${theme.colors.bottleGreen};
      font-weight: 500;
      outline: none;
      font-family: ${theme.fontFamily.text};
      transition: all ${theme.transitions.normal};
      :focus {
        border-color: ${theme.colors.bottleGreen};
      }
      flex-grow: 1;
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding', mobile.borderRadius, desktop.borderRadius)}
  `;
  }}
`;

export const StyledButton = styled.button`
  margin: 1.5rem auto 0;
  ${Button}
`;
